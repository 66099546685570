import React from "react";
import PageSubTitle from "../../components/pageSubTitle";
import DropzoneUpload from "../../components/formComponents/DropzoneUpload";
// import CustomButton from "../../components/CustomButton";
import { useSelector } from "react-redux";
import { BUCKET_PUBLIC_URL } from "../../constants";

function Documents({ requiredDocumentsList, documentsData, setDocumentsData }) {
  // const [documentsData, setDocumentsData] = useState({})

  const auth = useSelector((state) => state.auth);

  const handleChange = (index, e) => {
    setDocumentsData((prev) =>
      prev.map((x, i) =>
        index === i ? { ...x, [e.target.name]: e.target.value } : x
      )
    );
  };
  return (
    <div class="pro-dashboard-content my-5">
      <div class="container-fluid">               
        <div class="manage-doc-content">
          <div class="manage-doc-section">
            <div class="manage-doc-section-content">           
              <PageSubTitle pageTitle="Upload Documents" />

              <div className="row">
                {requiredDocumentsList &&                   
                  requiredDocumentsList.map((x, index) => (
                    <div className=" bg-light my-3 p-3">
                      <div className=" bg-light my-3 p-3">                  
                        {/* <DropzoneUpload /> */}
                        {/* {x.hasBackSide && <DropzoneUpload />} */}
                        <form>
                          {/* <!-- Document Name Input --> */}
     
                          <div className="row">
                            <div class="mb-3 col-12 col-md-3">
                              <label for="documentName" class="form-label">
                                Document Name
                              </label>
                              <input
                                type="text"      
                                class="form-control"
                                id="documentName"
                                name="documentName"
                                required
                                value={x.name + " ( Front Side )"}           
                                disabled
                              />
                            </div>
                            {/* <!-- Expiry Date Input --> */}
                            {x.hasExpiry ? (
                              <div class="mb-3 col-12 col-md-2">
                                <label for="expiryDate" class="form-label">
                                  Expiry Date
                                </label>            
                                <input
                                  type="date"
                                  class="form-control"
                                  id="expiryDate"                                                
                                  name="expirationDate"
                                  required
                                  onChange={(e) => handleChange(index, e)}
                                />
                              </div>            
                            ) : (
                              <div class="mb-3 col-12 col-md-2">
                                <label for="expiryDate" class="form-label">
                                  Expiry Date         
                                </label>
                                <p>Expiry date not required!</p>
                              </div>
                            )}

                            {/* <!-- Document Upload Input --> */}              
                            <div class="mb-3 col-12 col-md-5">
                              <DropzoneUpload
                                name="frontSideUrl"
                                onChange={(e) => handleChange(index, e)}
                                directory={`driver-uploads/${auth.userInfo._id}/${documentsData[index]?.documentType}/front/`}
                                isEnabled={                    
                                  !x.hasExpiry
                                    ? true                         
                                    : documentsData[index]?.expirationDate
                                }
                                disableReason="Please select expiry date first!"
                              />
                            </div>
                            <div className="mb-3 col-12 col-md-2">
                              {documentsData[index]?.frontSideUrl && (
                                <img                            
                                  src={`${BUCKET_PUBLIC_URL}/${documentsData[index]?.frontSideUrl}`}
                                  height={150}
                                  alt=""
                                />
                              )}
                            </div>
                            {/* <div class="mb-3 col-12 col-md-6">
                            <label for="documentUpload" class="form-label">
                              Upload Document
                            </label>
                            <input
                              type="file"
                              class="form-control"
                              id="documentUpload"
                              name="documentUpload"
                              required
                            />
                          </div> */}
                          </div>
                          {/* <!-- Submit Button --> */}
                          {/* <button type="submit" class="btn btn-primary">
                          Upload Document
                        </button> */}
                        </form>
                      </div>
                      {x.hasBackSide && (
                        <div className=" bg-light my-3 p-3">    
                          {/* <DropzoneUpload /> */}
                          {/* {x.hasBackSide && <DropzoneUpload />} */}
                          <form>
                            {/* <!-- Document Name Input --> */}

                            <div className="row">
                              <div class="mb-3 col-12 col-md-3">
                                <label for="documentName" class="form-label">
                                  Document Name
                                </label>
                                <input               
                                  type="text"
                                  class="form-control"
                                  id="documentName"
                                  name="documentName"             
                                  required
                                  value={x.name + " ( Back Side )"}
                                  disabled
                                />   
                              </div>
                              {/* <!-- Expiry Date Input --> */}

                              <div class="mb-3 col-12 col-md-2">
                                <label for="expiryDate" class="form-label">
                                  Expiry Date
                                </label>
                                <p>Expiry date not required!</p>
                              </div>                               

                              {/* <!-- Document Upload Input --> */}
                              <div class="mb-3 col-12 col-md-5">
                                <DropzoneUpload
                                  name="backSideUrl"                      
                                  onChange={(e) => handleChange(index, e)}
                                  directory={`driver-uploads/${auth.userInfo._id}/${documentsData[index]?.documentType}/back/`}
                                  isEnabled={
                                    !x.hasExpiry
                                      ? true
                                      : documentsData[index]?.expirationDate                   
                                  }
                                  disableReason="Please select expiry date first!"
                                />
                              </div>
                              <div className="mb-3 col-12 col-md-2">
                                {documentsData[index]?.backSideUrl && ( 
                                  <img
                                    src={`${BUCKET_PUBLIC_URL}/${documentsData[index]?.backSideUrl}`}
                                    height={150}
                                    alt=""
                                  />          
                                )}
                              </div>

                              {/* <div class="mb-3 col-12 col-md-6">
                            <label for="documentUpload" class="form-label">
                              Upload Document
                            </label>
                            <input
                              type="file"
                              class="form-control"
                              id="documentUpload"
                              name="documentUpload"
                              required
                            />
                          </div> */}
                            </div>
                            {/* <!-- Submit Button --> */}
                            {/* <button type="submit" class="btn btn-primary">
                          Upload Document
                        </button> */}
                          </form>
                        </div>
                      )}
                      {/* <CustomButton title="Upload" /> */}
                    </div>
                  ))}
                {/* {documentsArray.map((x) => (
                  <div className="col-12 col-lg-6 my-2">
                    <div class="manage-doc-box row no-margin bg-grey mr-1 py-2 h-100">
                      <div class="col-4">
                        <div class="manage-doc-box-left d-flex align-items-left">
                          <h6 class="manage-txt">{x.label}</h6>

                        </div>

                        <img
                          src={
                            documentsData[x.field] !== ""
                              ? documentsData[x.field]
                              : "/assets/images/no-image-2.png"
                          }
                          // className="h-100"
                          className="verify-doc-image"
                        />
                      </div>
                      
                      <div class="col-8">
                        <DropzoneUpload
                          fileUrlHandler={(fileUrl) =>
                            setDocumentsData({
                              ...documentsData,
                              [x.field]: fileUrl,
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                ))} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Documents;
